














































































































import { Component, Watch } from "vue-property-decorator";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import moment from "moment";
import AnaliticalReport from "./AnaliticalReport.vue";
import StatisticalReport from "./StatisticalReport.vue";
import {
  BaseBreadcrumb,
  ConfirmDelete,
  PageHeader,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import { mapGetters } from "vuex";

@Component({
  components: {
    BaseBreadcrumb,
    ConfirmDelete,
    PageHeader,
    AnaliticalReport,
    StatisticalReport,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class Report extends Request {
  companyId = this.$route.query.companyId;
  entity = this.$route.query.entity;
  title: string = `${translate("reports")}`;
  originalEndDate: string = "";
  originalStartDate: string = "";
  startDate: string = "";
  endDate: string = "";
  validDateSame: boolean = false;
  valid: boolean = false;
  isAnalitical: boolean = false;
  showAnalitical: boolean = false;
  showStatistical: boolean = false;
  menuStartDate: boolean = false;
  menuEndDate: boolean = false;
  itemsDownload = [];
  editorUserId: number;
  getUser: any;
  dateReference: string = "01/06/2023";
  dataComparison: any;
  breadcrumbs = [
    {
      text: `${translate("addCompany")}`,
      disabled: false,
      to: {
        name: "CompanyList",
        query: {
          entity: this.$route.query.entity,
        },
      },
    },
    {
      text: `${translate("companyProduct")}`,
      disabled: false,
      to: {
        name: "CompanyProductList",
        query: {
          entity: this.$route.query.entity,
          companyId: this.$route.query.companyId,
        },
      },
    },
    {
      text: `${translate("reports")}`,
      disabled: true,
    },
  ];
  pageHeader: TPageHeader = {
    mainIcon: "mdi-briefcase",
    titlePage: `${translate("reports")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  conversationReport = [];
  conversationReportNew = [];
  attendencyReport = [];
  listConversation = [];

  path: string = "";

  async search() {
    const user = this.getUser();
    this.editorUserId = user.id;

    let dataComparison = moment.tz(
      this.dateReference,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateStart = moment.tz(
      this.startDate,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateEnd = moment.tz(this.endDate, "DD/MM/YYYY", "America/Sao_Paulo");

    let conversationResult;
    let conversationResultNew;

    this.showAnalitical = false;
    this.showStatistical = false;
    this.conversationReport = [];
    this.conversationReportNew = [];
    this.valid = false;
    this.validDateSame = false;
    this.attendencyReport = [];

    const localEntity = {
      companyId: Number(this.$route.query.companyId),
      scope: this.$route.query.scope,
    };

    if (dateStart.isAfter(dataComparison) && dateEnd.isAfter(dataComparison)) {
      conversationResult = await this.get(
        this.paths.NewReportConversationPath +
          "?companyId=" +
          localEntity.companyId +
          "&scope=" +
          localEntity.scope +
          "&endDate=" +
          this.originalEndDate +
          "&startDate=" +
          this.originalStartDate +
          "&isAnalitical=" +
          this.isAnalitical
      );
      this.valid = true;
    } else if (
      (dateStart.isSame(dataComparison) && dateEnd.isSame(dataComparison)) ||
      (dateStart.isSame(dataComparison) && dateEnd.isAfter(dataComparison))
    ) {
      conversationResult = await this.get(
        this.paths.reportConversationPath +
          "?companyId=" +
          localEntity.companyId +
          "&scope=" +
          localEntity.scope +
          "&endDate=" +
          this.originalEndDate +
          "&startDate=" +
          this.originalStartDate +
          "&isAnalitical=" +
          this.isAnalitical
      );

      conversationResultNew = await this.get(
        this.paths.NewReportConversationPath +
          "?companyId=" +
          localEntity.companyId +
          "&scope=" +
          localEntity.scope +
          "&endDate=" +
          this.originalEndDate +
          "&startDate=" +
          this.originalStartDate +
          "&isAnalitical=" +
          this.isAnalitical
      );
      this.validDateSame = true;
    } else {
      conversationResult = await this.get(
        this.paths.reportConversationPath +
          "?companyId=" +
          localEntity.companyId +
          "&scope=" +
          localEntity.scope +
          "&endDate=" +
          this.originalEndDate +
          "&startDate=" +
          this.originalStartDate +
          "&isAnalitical=" +
          this.isAnalitical
      );
      this.valid = false;
    }
    const attendencyResult = await this.get(
      this.paths.reportAttendencyPath +
        "?companyId=" +
        localEntity.companyId +
        "&scope=" +
        localEntity.scope +
        "&endDate=" +
        this.originalEndDate +
        "&startDate=" +
        this.originalStartDate +
        "&isAnalitical=" +
        this.isAnalitical
    );

    if (this.isAnalitical) {
      if (
        (dateStart.isSame(dataComparison) && dateEnd.isSame(dataComparison)) ||
        (dateStart.isSame(dataComparison) && dateEnd.isAfter(dataComparison))
      ) {
        this.conversationReport = conversationResult;
        this.conversationReportNew = conversationResultNew;
        this.attendencyReport = attendencyResult.result;

        this.conversationReport.map((element) => {
          element.requiredDate = moment(element.requiredDate).format("DD/MM");
        });
        this.conversationReportNew.map((element) => {
          element.requiredDate = moment(element.requiredDate).format("DD/MM");
        });

        this.attendencyReport.map((element) => {
          element.requiredDate = moment(element.requiredDate).format("DD/MM");
        });

        this.showAnalitical = true;
      } else {
        this.conversationReport = conversationResult;
        this.attendencyReport = attendencyResult.result;

        this.conversationReport.map((element) => {
          element.requiredDate = moment(element.requiredDate).format("DD/MM");
        });
        this.attendencyReport.map((element) => {
          element.requiredDate = moment(element.requiredDate).format("DD/MM");
        });

        this.showAnalitical = true;
      }
    } else if (this.valid == true) {
      this.conversationReport.push(
        {
          nameDirection:
            conversationResult.authentication.resultAuthentication[0]
              .nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue:
            conversationResult.authentication.AuthenticationRegularAmount,
        },
        {
          nameDirection:
            conversationResult.marketing.resultMarketing[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.marketing.MarketingRegularAmount,
        },

        {
          nameDirection:
            conversationResult.service.resultService[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResult.service.ServiceFreeLevelAmount,
        },
        {
          nameDirection:
            conversationResult.service.resultService[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.service.ServiceRegularAmount,
        },

        {
          nameDirection:
            conversationResult.service.resultService[0].nameDirection,
          nameType: `${translate("FREE_ENTRY_POINT")}`,
          totalValue: conversationResult.service.ServiceFreePointAmount,
        },
        {
          nameDirection:
            conversationResult.utility.resultUtility[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.utility.UtilityRegularAmount,
        }
      );
      if (this.attendencyReport.length <= 0) {
        this.attendencyReport.push({ totalValue: attendencyResult.value });
      }

      this.showStatistical = true;
    } else if (this.validDateSame) {
      this.conversationReport.push(
        {
          nameDirection:
            conversationResultNew.authentication.resultAuthentication[0]
              .nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue:
            conversationResultNew.authentication.AuthenticationRegularAmount,
        },
        {
          nameDirection:
            conversationResultNew.marketing.resultMarketing[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResultNew.marketing.MarketingRegularAmount,
        },

        {
          nameDirection:
            conversationResultNew.service.resultService[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResultNew.service.ServiceFreeLevelAmount,
        },
        {
          nameDirection:
            conversationResultNew.service.resultService[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResultNew.service.ServiceRegularAmount,
        },

        {
          nameDirection:
            conversationResultNew.service.resultService[0].nameDirection,
          nameType: `${translate("FREE_ENTRY_POINT")}`,
          totalValue: conversationResultNew.service.ServiceFreePointAmount,
        },
        {
          nameDirection:
            conversationResultNew.utility.resultUtility[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResultNew.utility.UtilityRegularAmount,
        },

        {
          nameDirection:
            conversationResult.business.resultBusiness[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResult.business.BusinessFreeLevelAmount,
        },
        {
          nameDirection:
            conversationResult.business.resultBusiness[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.business.BusinessRegularAmount,
        },

        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResult.user.UserFreeLevelAmount,
        },
        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.user.UserRegularAmount,
        },

        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("FREE_ENTRY_POINT")}`,
          totalValue: conversationResult.user.UserFreePointAmount,
        }
      );
      if (this.attendencyReport.length <= 0) {
        this.attendencyReport.push({ totalValue: attendencyResult.value });
      }

      this.showStatistical = true;
    } else {
      this.conversationReport.push(
        {
          nameDirection:
            conversationResult.business.resultBusiness[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResult.business.BusinessFreeLevelAmount,
        },
        {
          nameDirection:
            conversationResult.business.resultBusiness[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.business.BusinessRegularAmount,
        },

        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("FREE_TIER")}`,
          totalValue: conversationResult.user.UserFreeLevelAmount,
        },
        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("REGULAR")}`,
          totalValue: conversationResult.user.UserRegularAmount,
        },

        {
          nameDirection: conversationResult.user.resultUser[0].nameDirection,
          nameType: `${translate("FREE_ENTRY_POINT")}`,
          totalValue: conversationResult.user.UserFreePointAmount,
        }
      );
      if (this.attendencyReport.length <= 0) {
        this.attendencyReport.push({ totalValue: attendencyResult.value });
      }

      this.showStatistical = true;
    }

    this.startDate = moment(this.originalStartDate).format("DD/MM/YYYY");
    this.endDate = moment(this.originalEndDate).format("DD/MM/YYYY");
  }

  get maxDate() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return yesterday.toISOString().split("T")[0];
  }

  get missingStartDate() {
    return !this.startDate;
  }
  get missingEndDate() {
    return !this.endDate;
  }

  get validDateLimit() {
    let dataComparison = moment.tz(
      this.dateReference,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateStart = moment.tz(
      this.startDate,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateEnd = moment.tz(this.endDate, "DD/MM/YYYY", "America/Sao_Paulo");

    return (
      (dateStart.isBefore(dataComparison) && dateEnd.isAfter(dataComparison)) ||
      (dateStart.isAfter(dataComparison) && dateEnd.isBefore(dataComparison)) ||
      (dateStart.isBefore(dataComparison) && dateEnd.isSame(dataComparison))
    );
  }
  get validDateInverted() {
    let dateStart = moment.tz(
      this.startDate,
      "DD/MM/YYYY",
      "America/Sao_Paulo"
    );
    let dateEnd = moment.tz(this.endDate, "DD/MM/YYYY", "America/Sao_Paulo");

    return dateStart.isAfter(dateEnd);
  }
  get searchDisabled() {
    return (
      this.missingStartDate ||
      this.missingEndDate ||
      this.validDateLimit ||
      this.validDateInverted
    );
  }
  @Watch("originalStartDate")
  formatStartDate(value) {
    this.startDate = moment(value).format("DD/MM/YYYY");
  }
  @Watch("originalEndDate")
  formatEndDate(value) {
    this.endDate = moment(value).format("DD/MM/YYYY");
  }
  sortByData(a, b) {
    const date1 = new Date(a.requiredDate);
    const date2 = new Date(b.requiredDate);

    return date1 > date2 ? 1 : -1;
  }
  mounted() {
    this.entity = this.$route.query.entity;
  }
}
